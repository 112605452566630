import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { intervalToDuration } from "date-fns";
import { useTranslation } from "react-i18next";
import { CardSignatureProps } from "./CardSignature.types";
import { LEVEL } from "../../consts";
import { formatDate } from "../../services";
import { Icon } from "../Icon";

export function CardSignature({
  direction = "row",
  duration,
  ecm_specialization,
  editionNumber,
  endDate,
  expirationDate,
  gap = 1,
  isHighLightEdition=false,
  learningActivityNumber=0,
  learningObjectTypology,
  meetingDetails,
  minimumScore,
  resources,
  startDate,
  temptative,
  isSurvey,
  isTest,
  isToj,
  ...props
}: CardSignatureProps) {

  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const interval = intervalToDuration({
    end: !isNaN(Number(duration) || 0) && duration ?  Number(duration) * 60 * 1000 : 60 * 1000,
    start: 0 }) as Duration ;

  const meetingStartDate = meetingDetails?.[0]?.startDate?.split(" ")?.[0];
  const meetingEndDate = meetingDetails?.[0]?.endDate?.split(" ")?.[0];


  const dateFormatted = () =>  {
    if(meetingStartDate && meetingEndDate){
      if(meetingStartDate !== meetingEndDate){
        return {
          date: meetingStartDate?.replaceAll("-","/"),
          end:meetingEndDate?.replaceAll("-","/"),
          type: "ecm_2"
        };
      }
      else {
        return {
          date:meetingStartDate,
          end:"",
          type: "ecm"
        };
      }
    }
    if(!startDate ){
      if(
        learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
        || learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
      ) {
        return   {
          date:"",
          end:"",
          type:"editions"
        };
      } else if (expirationDate){
        return {
          date: formatDate( expirationDate.join("/"), i18n.language),
          end:"",
          type:"normal"
        };
      }  else {
        return   {
          date:"",
          end:"",
          type:"normal"
        };
      }
    }
    else if (endDate && (startDate.join("/") !== endDate.join("/"))) {
      return {
        date: formatDate(startDate.join("/"), i18n.language),
        end:formatDate(endDate.join("/"), i18n.language),
        type: ecm_specialization || isToj ? "ecm_2" :"register_2"
      };
    }
    else {
      return {
        date: formatDate( startDate.join("/"), i18n.language),
        end:"",
        type: ecm_specialization || isToj ? "ecm" :"register"
      };
    }
  };

  const labelRegister1 = ` ${!isHighLightEdition ? `${t("registered")} : ` : "  "} ${dateFormatted().date} `;
  const labelRegister2 = `${!isHighLightEdition ? `${t("registered")} : ` : " "}
  ${t("from")} ${dateFormatted().date} ${t("to")} ${dateFormatted().end}`;

  const labelEcm1 = `${dateFormatted().date} `;

  const labelEcm2= `${t("from")} ${dateFormatted().date} ${t("to")} ${dateFormatted().end}`;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Workaround. Alcune lungue usano il . o - invece del / per separare giorni mesi e anni. Aggiungo un replaceAll come soluzione temporanea.
  // TODO: Bisognerebbe gestire l'oggetto come tipo Date e controllare un range, invece di controllare la stringa esatta, che può differire in base alla lingua.
  const isDateInfinite = (formattedDate: string)=>{
    let tempFormatted = formattedDate?.replaceAll(" ", "").replaceAll(".","/").replaceAll("-","/");
    return !!tempFormatted &&
    !((tempFormatted.indexOf("30/12/9999") >= 0)
      || (tempFormatted.indexOf("31/12/9999") >= 0)
      || (tempFormatted.indexOf("12/31/9999") >= 0)
      || (tempFormatted.indexOf("12/30/9999") >= 0)
    );
  };

  return (
    <Grid
      item
      container
      className={ "card-signature" }
      display={ "flex" }
      flexDirection={ direction }
      gap={ gap }
      height={ "fit-content" }
      { ...props }
    >
      {
        /* duration */
        duration && !isToj && ! meetingDetails ? (
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }

          >
            <Icon
              icon={ "Icons_time" }
              size={ 20 }
              color={ theme.customColors.systemPrimary01 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.875rem",
                  lineHeight: "18px"
                }
              } }
            >
              { interval.days ? t(interval.days === 1 ? "day_one" : "day_other", { count: interval.days }) : null }
              { interval.hours ? " " +
                t(interval.hours === 1 ? "countHour_one" : "countHour_other", { count: interval.hours }) : null }
              {  interval.minutes ? " " + interval.minutes + " " + t("min") : null }
            </Typography>
          </Stack>
        ):
          null
      }

      {
        /* tempatives */
        temptative ? (
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }

          >
            <Icon
              icon={ "test" }
              size={ 20 }
              color={ theme.customColors.systemPrimary01 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.875rem",
                  lineHeight: "18px"
                }
              } }
            >
              { t("temptatives", { count: temptative }) }
            </Typography>
          </Stack>
        ):
          null
      }
      {
        /* minimunScore */
        minimumScore && !isSurvey && isTest ? (
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }

          >
            <Icon
              icon={ "completato" }
              size={ 20 }
              color={ theme.customColors.systemPrimary01 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.875rem",
                  lineHeight: "18px"
                }
              } }
            >
              { t("minimum_score", { count: minimumScore }) }
            </Typography>
          </Stack>
        ):
          null
      }

      {
        /* activities */
        (learningActivityNumber > 0) ? (
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }
          >
            <Icon
              icon={ "attivita" }
              size={ 20 }
              color={ theme.customColors.systemPrimary01 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.875rem"
                }
              } }
            >
              { t(learningActivityNumber === 1 ? "activity_one" : "activity_other", { count: learningActivityNumber }) }
            </Typography>
          </Stack>
        ) : null
      }
      {
        /* resources */
        resources ? (
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }
          >
            <Icon
              icon={ "Icons_risorse" }
              color={ theme.customColors.systemPrimary01 }
              size={ 20 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.875rem"
                }
              } }
            >
              { t("resource", { count: resources }) }
            </Typography>
          </Stack>
        ) : null
      }
      { /* editions */
        (
          dateFormatted().type === "editions" &&
          editionNumber &&
          editionNumber > 0
        )  ?
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }
          >
            <Icon
              icon={ "Icons_scadenza-e-edizioni" }
              color={ theme.customColors.systemPrimary01 }
              size={ 20 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.875rem"
                }
              } }
            >
              { t("editions", { count: editionNumber }) }
            </Typography>
          </Stack> : null
      }
      { /* date */
        isDateInfinite(dateFormatted().date) ?
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }
          >
            <Icon
              icon={ "Icons_scadenza-e-edizioni" }
              color={ theme.customColors.systemPrimary01 }
              size={ isMobile ? 17 : 20 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                  lineHeight: "1.125rem"
                }
              } }
            >
              {
                dateFormatted().type === "register_2"
                  ? labelRegister2
                  : dateFormatted().type === "register"
                    ? labelRegister1 :
                    dateFormatted().type === "ecm_2"
                      ? labelEcm2
                      : dateFormatted().type === "ecm"
                        ? labelEcm1
                        : `${t("expiring_on")} ${dateFormatted().date}`
              }
            </Typography>
          </Stack> :
          null
      }

      {
        meetingDetails?.[0]?.meetingNumber ?
          <Stack
            alignItems="center"
            direction="row"
            gap={ 1 }
            height={ "fit-content" }
          >
            <Icon
              icon={ "meetings" }
              color={ theme.customColors.systemPrimary01 }
              size={ 20 }
            />
            <Typography
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.875rem"
                }
              } }
            >
              { t("meetings", { count: meetingDetails?.[0]?.meetingNumber }) }
            </Typography>
          </Stack> : null
      }
    </Grid>
  );
}
