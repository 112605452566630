import { Box, Grid, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "../Icon";

const searchBarHeight = "2.5";
const searchBarWidth = "25rem";
const searchBarIconSize = "1.75rem";


export const Search = styled("div")(({ theme }) => ({
  backgroundColor: theme.customColors.backgroundSecondary,
  borderRadius: theme.spacing(0.25),
  height: searchBarHeight,
  marginLeft: "-40px",
  maxWidth: searchBarWidth,
  position: "relative",
  width:"60%",
  zIndex: theme.zIndex.fab,

  [theme.breakpoints.down("sm")]: {
    display:"none",
    marginLeft: 0
  }
}));
export const ChatBotIcon = styled(Grid)(() => ({
  cursor:"pointer",
  height:"24px",
  width:"24px",

  "img":{
    height:"100%",
    width:"100%",

    objectFit:"contain"
  }
}));

export const SearchIcon = styled(Icon)(() => ({}));

export const SearchIconWrapper = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  cursor:"pointer",
  display: "flex",
  fontSize: searchBarIconSize,
  height: "100%",
  padding: theme.spacing(0, 2),
  position: "absolute",
  zIndex:1,
  [theme.breakpoints.down("sm")]: {
    padding: "0",
    position: "unset"
  },

  [`${SearchIcon}`]: {
    "path": {
      fill:theme.palette.primary?.main
    }
  }
}));

export const CancelIcon = styled(Icon)(() => ({}));

export const CancelIconWrapper = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  fontSize: searchBarIconSize,
  height: "100%",
  padding: theme.spacing(0, 2),
  position: "absolute",
  right:0,
  top:0,

  [`${CancelIcon}`]: {
    "path": {
      fill:theme.palette.primary?.main
    }
  }
}));

export const SearchInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: theme.customColors.textInput,
    padding: theme.spacing(1.25, 1, 1.25, 0),
    paddingLeft: `calc(${searchBarIconSize} + ${theme.spacing(3)})`,
    paddingRight: `calc(${searchBarIconSize} + ${theme.spacing(3)})`,
    [theme.breakpoints.down("sm")]: {
      lineClamp: 1,
      marginLeft: "4px",
      padding: "0",
      textOverflow: "ellipsis",
      width: "80%"

    },


    "&::placeholder ":{
      color:theme.customColors.backgroundDisabled,
      fontFamily:"Inter",
      fontWeight:400,
      opacity:1,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight:"18px"


      }

    }
  },
  width: "100%"
}));

export const StylesresultsSearchMobile = styled(Box)(({ theme }) => ({

  padding:theme.spacing(2, 2.5),
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  }


}));
