import { QueryClient } from "@tanstack/react-query";
import {
  useState,
  useEffect
} from "react";
import {
  createBrowserRouter,
  redirect,
  RouterProvider as ReactRouterRouterProvider,
  useRouteError
} from "react-router-dom";
import fetchUserMgmt from "./fetchUserMgmt";
import { useNavigate } from "./navigate";
import {
  Agreements,
  Login,
  OnBoarding,
  Scorm,
  Password,
  PasswordChange,
  PasswordCode,
  PasswordRecovery,
  ProfileCapabilities,
  ProfileProfession,
  ProfileJob,
  ProfilePreferences,
  ProfileSkills,
  SignupDomain,
  SignupSummary,
  Modal
} from "../components";
import Register, { loader as registerLoader } from "../components/Register/Register.component";
import { RegisterStateProvider } from "../context/RegisterContext";
import { UserNavigationContext, useUserNavigationProvider } from "../context/UserNavigationContext";
import {
  Access,
  ActivitiesDetailPage,
  ActivitiesRelatedPage,
  Agenda,
  Bookmarks,
  Category,
  Collection,
  Community,
  CommunityDetails,
  CookiePage,
  Explore,
  ExploreDetail,
  ExploreWatch,
  Homepage,
  Landing,
  Lesson,
  NotFoundPage,
  PrivacyPage,
  Profile,
  Refresh,
  Root,
  Search,
  SmartLearning,
  Test,
  TermsAndCondition,
  Maintenance
} from "../routes";
import { IconSetPage } from "../routes/IconSet";
import { useAuthStore } from "../stores";
import { getError } from "../utils/error";
import { getEnvironmentVariables } from "../utils/general";
import { CustomRegex } from "../utils/regex";
/*import { Community} from "../routes";*/

const { basePath, clientId } = getEnvironmentVariables();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: Infinity,
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        let shouldGiveUp = false;
        if (typeof error === "string") { //|| error instanceof(String)) {
          try {
            const parsedError = JSON.parse(error ?? {});
            if (parsedError.errors instanceof Object) {
              shouldGiveUp = Object.values(parsedError.errors).some((errorCategory) => {
                if (!Array.isArray(errorCategory)) return false;
                return errorCategory.some((error) => error.retry === false);
              });
            }
          } catch {
            shouldGiveUp = false;
          }
        }
        if (failureCount < 3 && !shouldGiveUp) {
          return true;
        } else {
          // eslint-disable-next-line no-console
          // console.error("last retry attempt failed\n", error);
          return false;
        }
      },
      retryDelay: (attemptIndex) => (
        Math.min(1000 * 5 ** attemptIndex, 30000)
      )
      // the staleTime setting is only valid on the hook itself, defaults to 0
    }
  }
});

export const RouterProvider = () => {
  const isAuthenticated = useAuthStore(state => Boolean(state.session));
  const signInSSO = useAuthStore(state => state.signInSSO);
  const signOut = useAuthStore(state => state.signOut);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const { navigation, setNavigation } = useUserNavigationProvider();

  const router = createBrowserRouter([
    {
      children: [
        {
          children: [
            // login form
            {
              element: <Login />,
              loader: async () => {
                if (isAuthenticated) {
                  return redirect("/");
                } else {
                  return await fetchUserMgmt({ basePath, clientId });
                }
              },
              path: "login"
            },
            // password
            {
              element: <Password />,
              path: "password",
              loader: async () => {
                return await fetchUserMgmt({ basePath, clientId });
              }
            },
            {
              element: <PasswordChange />,
              path: "password/change"
            },
            {
              element: <PasswordRecovery />,
              loader: async () => {
                signOut(null);
                return await fetchUserMgmt({ basePath, clientId });
              },
              path: "password/recovery"
            },
            {
              element: <PasswordCode />,
              loader: async () => {
                return await fetchUserMgmt({ basePath, clientId });
              },
              path: "password/code"
            },
            // select initiative domain
            {
              element: <SignupDomain />,
              path: "portal"
            }
          ],
          element: <Access/>,
          loader: async ({ request }) => {
            const path = CustomRegex.matchPathLeaf(request.url);
            if (path === "access") {
              return redirect("/access/login");
            } else {
              return null;
            }
          },
          path: "access"
        },
        {
          // profile
          children: [
            {
              element: <ProfileProfession/>,
              path: "profession"
            },
            {
              element: <ProfileJob />,
              path: "job"
            },
            {
              element: <ProfileSkills />,
              path: "skills"
            },
            {
              element: <ProfileCapabilities />,
              path: "capabilities"
            },
            {
              element: <ProfilePreferences />,
              path: "preferences"
            },
            {
              element: <SignupSummary />,
              path: "summary"
            }
          ],
          element: <OnBoarding/>,
          path: "signup"
        },
        // consents
        {
          element: <Agreements/>,
          path: "agreements"
        },
        {
          element: <RegisterStateProvider><Register/></RegisterStateProvider>,
          loader: ()=> registerLoader({ basePath, clientId }),
          path: "register"
        },
        // pages
        {
          element: <Agenda/>,
          path: "agenda"
        },
        {
          element: <Bookmarks/>,
          path: "bookmarks"
        },
        {
          element: <Collection/>,
          path: "percorso/:id"
        },
        // {
        //   element: <Example/>,
        //   loader: loader([
        //     contentsQuery,
        //     eventsQuery,
        //     packagesQuery
        //   ]),
        //   path: "example"
        // },
        {
          element: <ExploreDetail/>,
          path: "/esplora/dettaglio/:id/:learningObjectTypology"
        },
        {
          element: <ExploreWatch/>,
          path: "/esplora/guarda/:id/:learningObjectTypology"
        },
        {
          element: <ExploreDetail/>,
          path: "/per-te/dettaglio/:id/:learningObjectTypology"
        },
        {
          element: <ExploreWatch/>,
          path: "/per-te/guarda/:id/:learningObjectTypology"
        },
        {
          element: <Category/>,
          path: "/esplora/categories/:category"
        },
        {
          element: <Explore/>,
          path: "esplora"
        },
        {
          element: <Homepage/>,
          loader: async() => {
            if(smartConfiguration?.visForyou === false){
              return redirect("/");
            }
            else {
              return null;
            }
          },
          // loader(
          //   [
          //     // getStructuresQuery(
          //     //   refreshSession,
          //     //   {
          //     //     corporateId,
          //     //     idToken,
          //     //     initiativeId,
          //     //     pageName: PAGE_NAME.FOR_YOU_OVERVIEW,
          //     //     sessionToken,
          //     //     smartConfiguration
          //     //   }
          //     // )
          //   ])
          path: "per-te"
        },
        {
          element: <ActivitiesDetailPage/>,
          path: "activitiesDetail"
        },
        {
          element: <ActivitiesRelatedPage/>,
          path:"/esplora/related/:id"
        },
        {
          element: <Landing/>,
          path: "welcome"
        },
        {
          element: <Community/>,
          path: "community"
        },
        {
          element: <CommunityDetails/>,
          path: "community/:id"
        },
        {
          element: <CookiePage/>,
          path: "cookie"
        },
        {
          element: <Lesson/>,
          path: "lezione/:id"
        },
        {
          element: <TermsAndCondition/>,
          path: "terms-conditions"
        },
        {
          element: <PrivacyPage/>,
          path: "privacy"
        },
        {
          children: [
            {
              element: <Profile />,
              path: ""
            },
            {
              element: <ProfilePreferences />,
              path: "preferences"
            }
          ],
          path: "profile"
        },
        {
          element: <Search/>,
          path: "search/:term"
        },
        {
          element: <SmartLearning/>,
          path: "smart-learning"
        },
        {
          element: <NotFoundPage/>,
          path: "not-found"
        },
        {
          element: <IconSetPage/>,
          path: "icons"
        },
        // NOTE: test is meant for components which have issues with storybook
        // TODO: try out with storybook once updated to v7, starting 23/04/21
        {
          element: <Test/>,
          path: "colors"
        }
      ],
      element: <Root/>,
      errorElement: <RouterErrorBoundary />,
      loader: async({ request }) => {
        document.getElementById("lds-ring")?.remove();
        const requestUrl = new URL(request.url);
        const ssoCode = requestUrl.searchParams.get("code");
        const seedSession = requestUrl.searchParams.get("seed_session");

        if(seedSession){
          localStorage.clear();
        }
        const response = await fetchUserMgmt({ basePath, clientId });
        if(ssoCode) {
          signInSSO(ssoCode);
        }
        return response;

        // return null;
        // const subRoute = CustomRegex.matchSubRoute(request.url);
        // const region = subRoute ? CustomRegex.matchDirectChild(subRoute, "") : "";
        // if (!isAuthenticated) {
        //   return redirect("/access/login");
        // } else if (region === "") {
        //   return redirect("/per-te");
        // } else {
        //   return null;
        // }
      },
      path: ""
    },
    {
      element: <Scorm courseId={ null } rootId={ null }/>,
      loader: async() => {
        document.getElementById("lds-ring")?.remove();
        return null;
      },
      path: "/esplora/scorm/:id/:learningObjectTypology"
    },
    {
      //TODO: add ui with logout notice
      element: <></>,
      loader: async() => {
        document.getElementById("lds-ring")?.remove();
        signOut("/",true);
        return null;
      },
      path: "/logout"
    },
    {
      element: <Refresh />,
      loader: async() => {
        document.getElementById("lds-ring")?.remove();
        return null;
      },
      path: "/refresh"
    },
    {
      element: <Maintenance />,
      path: "maintenance-page"
    }
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("💻 ",import.meta.env.VITE_APP_NAME, "Env-Mode: ", import.meta.env.VITE_APP_NODE_ENV);
  }, []);

  return (
    <UserNavigationContext.Provider value={ { navigation, setNavigation } }>
      <ReactRouterRouterProvider router={ router }/>
    </UserNavigationContext.Provider>

  );
};

function RouterErrorBoundary() {
  // const routeError = useRouteError() as {
  //   data: string
  //   error: Error
  //   internal: boolean
  //   status: number
  //   statusText: string
  // };
  const routeError = useRouteError();

  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const err = getError(routeError)?.[0];

  // const error = getError(routeError.error);
  /* eslint-disable no-console */
  routeError && console.warn("RouterErrorBoundary", { routeError, show });
  /* eslint-enable no-console */
  return (
    <Modal
      description={ err?.message ?? "unknown" }
      open={ show }
      onClose={ () => {
        setShow(false);
        navigate("/");
      } }
      title={ err?.name ?? "Error" }
    />
  );
}
