/* eslint-disable */
import { Box, Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  BoxCmt,
  BoxContent,
  StyledAvatar
} from "./TutorialBox.styles";
import avatarIcon from "../../assets/avatar.svg";
import { ButtonText } from "../../components/Button";
import { Icon } from "../../components/Icon/Icon.component";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../stores";
import { NAVBAR_ITEMS } from "../../consts";
import { getLastPath } from "../../utils/general";

interface TutorialInterface {
  onMobileShowWidget?: (item: boolean) => void,
  onMobileShowUserMenu?: (item: boolean) => void,
}
const TutorialBox: React.FC<TutorialInterface> = ({ onMobileShowWidget, onMobileShowUserMenu }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const theme = useTheme();
  const [showTutorial, setShowTutorial] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const tutorialBoxRef = useRef(null)
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const enableCommunityFlag = useAuthStore(state => state.corporateInfo?.enable_community_flag);
  
  const steps  = useMemo(() => {
    let items = [
      {
        title: t("tutorial-step-1-title"),
        firstText: t("tutorial-step-1-firstText"),
        secondText: t("tutorial-step-1-secondText"),
        stepNumber: "1",
      },
      {
        title: t("tutorial-step-2-title"),
        firstText: t("tutorial-step-2-firstText"),
        secondText: t("tutorial-step-2-secondText"),
        id: "navbar",
        elementRef: "for_you",
        idStep: NAVBAR_ITEMS.FOR_YOU,
        stepNumber: "2",
        href: "/per-te"
      },
      {
        title: t("tutorial-step-3-title"),
        firstText: t("tutorial-step-3-firstText"),
        secondText: t("tutorial-step-3-secondText"),
        id: "side-menu",
        stepNumber: "3"
      },
      {
        title: t("tutorial-step-4-title"),
        firstText: t("tutorial-step-4-firstText"),
        secondText: t("tutorial-step-4-secondText"),
        id: "navbar",
        elementRef: "explore",
        stepNumber: "4",
        href: "/esplora"
      },
      {
        title: t("tutorial-step-5-title"),
        firstText: t("tutorial-step-5-firstText"),
        secondText: t("tutorial-step-5-secondText"),
        id: "navbar",
        elementRef: "bookmarks",
        stepNumber: "5",
        idStep: NAVBAR_ITEMS.BOOKMARKS,
        href: "/bookmarks"
      },
      {
        title: t("tutorial-step-6-title"),
        firstText: t("tutorial-step-6-firstText"),
        secondText: t("tutorial-step-6-secondText"),
        id: "navbar",
        elementRef: "community",
        stepNumber: "6",
        idStep: NAVBAR_ITEMS.COMMUNITY,
        href: "/community"
      },
      {
        title: t("tutorial-step-7-title"),
        firstText: t("tutorial-step-7-firstText"),
        secondText: t("tutorial-step-7-secondText"),
        id: "navbar",
        elementRef: "agenda",
        stepNumber: "7",
        idStep: NAVBAR_ITEMS.AGENDA,
        href: "/agenda"
      },
      {
        title: t("tutorial-step-8-title"),
        firstText: t("tutorial-step-8-firstText"),
        secondText: t("tutorial-step-8-secondText"),
        stepNumber: "8",
        id: "app-bar",
        elementRef: "chatbot",
        href: "/per-te"
      }
    ]

    if (smartConfiguration?.visForyou === false) {
      items = items.filter((item) => item.idStep !== NAVBAR_ITEMS.FOR_YOU);
    }
    if (smartConfiguration?.funcFavourites === false) {
      items = items.filter((item) => item.idStep !== NAVBAR_ITEMS.BOOKMARKS);
    }
    if (
      enableCommunityFlag === false
      || (enableCommunityFlag === true && smartConfiguration?.funcCommunity === false)
    ) {
      items = items.filter((item) => item.idStep !== NAVBAR_ITEMS.COMMUNITY);
    }
    if (smartConfiguration?.widgetCalendar === false) {
      items = items.filter((item) => item.idStep !== NAVBAR_ITEMS.AGENDA);
    }

    if (smartConfiguration?.widgetBadge === false && smartConfiguration?.widgetCalendar === false && smartConfiguration?.widgetContinueLearning === false) {
      items = items.filter((item) => item.id !== "side-menu");
    }

    return items;
  }, [smartConfiguration, enableCommunityFlag, t])

  const closeTutorial = () => {
    const elementToRemove = document.querySelector(`#${steps[activeStep].id}-clone`)
    elementToRemove?.remove()
    setShowTutorial(false)
    const expirationDate = new Date(new Date().getTime() +1000*60*60*24*365); // 1 year
    document.cookie = `tutorial-completed=true;secure; expires='${expirationDate.toUTCString()}'; domain=.${getLastPath()}; path=/`;
    navigate("/")
  }

  const goToNextStep = () => {
    const elementToRemove = document.querySelector(`#${steps[activeStep].id}-clone`)
    elementToRemove?.remove()

    if (steps.length - 1 === activeStep) {
      setShowTutorial(false)
      return
    }

    const newStepNumber = activeStep + 1
    const nextStep = steps[newStepNumber]
    nextStep?.href && navigate(`${nextStep?.href}`)
    const domElement = document.querySelector(`#${nextStep?.id}`)
    const newDomElement: any = domElement?.cloneNode(true)
    if (newDomElement) {
      const tutorialBoxElement: any = tutorialBoxRef.current
      newDomElement?.setAttribute('id', `${nextStep.id}-clone`)
      const position = domElement?.getBoundingClientRect()
      const tutorialBoxPosition = tutorialBoxElement.getBoundingClientRect()

      if (nextStep?.id === "side-menu" && position && tutorialBoxPosition.right > position.x) {
        tutorialBoxElement.style.cssText = "translate: -185px;"
      } else if (nextStep?.id === "navbar" && position && window.screen.height < 900) {
        tutorialBoxElement.style.cssText = "translate: 0 100px;"
      } else {
        tutorialBoxElement.removeAttribute("style")
      }

      newDomElement.style.cssText = `position: absolute; left: ${position?.x}px; top: ${position?.y}px; z-index: 1400; pointer-events: none; ${nextStep.id !== "side-menu" && 'width: 100%; animation: none'}`

      const ref = newDomElement.querySelector(`#${nextStep.elementRef}`)
      if (ref) {
        if (nextStep.elementRef) {
          if (nextStep.elementRef === "chatbot") {
            ref.style.cssText = "position: relative;"
            ref.classList.add('active-tutorial')

            const mark = document.createElement('div')
            mark.style.cssText = "position: absolute; left: 50%; top: 50%; translate: -52% -46%; width: 45px; height: 45px; border-radius: 100%; border: 1px solid #A6FF03; box-shadow: 0px 0px 24px 0px #A6FF0380; background: rgba(255,255,255,0.2)"
            ref.appendChild(mark)
          } else {
            ref.style.cssText = "position: relative; margin-bottom: 20px"
            ref.classList.add('active-tutorial')

            const mark = document.createElement('div')
            mark.style.cssText = "position: absolute; left: 50%; top: 50%; translate: -50% -40%; min-width: 120px; width: calc(100% + 20px); aspect-ratio: 1/1; border-radius: 100%; border: 1px solid #A6FF03; box-shadow: 0px 0px 24px 0px #A6FF0380; background: rgba(255,255,255,0.2)"
            ref.appendChild(mark)
          }
        }
      }
      document.body.appendChild(newDomElement)
    }

    setActiveStep(newStepNumber)
  }

  const goNextStepMobile = () => {
    const elementToRemove = document.querySelector(`#${steps[activeStep].id}-clone`)
    elementToRemove?.remove()

    if (steps.length - 1 === activeStep) {
      setShowTutorial(false)
      return
    }

    const newStepNumber = activeStep + 1
    const nextStep = steps[newStepNumber]
    nextStep.href && navigate(`${nextStep.href}`)
    if (nextStep.id === "side-menu") {
      onMobileShowWidget && onMobileShowWidget(true)
      setShowTutorial(false)
      document.body.style.pointerEvents = "none"
      setTimeout(() => {
        document.body.style.removeProperty("pointer-events")
        setShowTutorial(true)
        onMobileShowWidget && onMobileShowWidget(false)
      }, 5000);
    }
    if (nextStep.id === "app-bar") {
      onMobileShowUserMenu && onMobileShowUserMenu(true)
      setShowTutorial(false)
      document.body.style.pointerEvents = "none"
      setTimeout(() => {
        document.body.style.removeProperty("pointer-events")
        setShowTutorial(true)
        onMobileShowUserMenu && onMobileShowUserMenu(false)
      }, 5000);
    }
    const domElement = document.querySelector(`#${nextStep.id}`)
    const newDomElement: any = domElement?.cloneNode(true)
    if (newDomElement) {
      newDomElement?.setAttribute('id', `${nextStep.id}-clone`)
      const position = domElement?.getBoundingClientRect()

      setShowTutorial(false)
      document.body.style.pointerEvents = "none"
      setTimeout(() => {
        document.body.style.removeProperty("pointer-events")
        setShowTutorial(true)
      }, 5000);

      newDomElement.style.cssText = `position: absolute; left: ${position?.x}px; top: ${position?.y}px; z-index: 1400; pointer-events: none; ${nextStep.id !== "side-menu" && 'width: 100%; animation: none'}`

      const ref = newDomElement.querySelector(`#${nextStep.elementRef}`)
      if (ref) {
        if (nextStep.elementRef) {
          ref.style.cssText = "position: relative; border: 0;"
          ref.classList.add('active-tutorial')

          const mark = document.createElement('div')
          mark.style.cssText = "position: absolute; left: 50%; top: 0; translate: -50% -35%; width: 85px; aspect-ratio: 1/1; border-radius: 100%; border: 1px solid #A6FF03; box-shadow: 0px 0px 24px 0px #A6FF0380; background: rgba(255,255,255,0.2)"
          ref.appendChild(mark)
        }
      }

      document.body.appendChild(newDomElement)
    }

    setActiveStep(newStepNumber)
  }

  return (
    <Dialog open={showTutorial} sx={{
      [theme.breakpoints.up("md")]: {
        marginTop: "2rem"
      },
      ".MuiDialog-paper": {
        overflowY: "unset",
        background: "transparent",
        boxShadow: "none"
      }
    }}>
      <BoxCmt id="test" ref={tutorialBoxRef}>
        {isMobile ? <svg width="375" height="410" viewBox="0 0 375 410" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_bd_2_107894)">
            <path d="M18.7359 4C24.257 4 28.1499 9.56073 28.0115 15.0802C28.0039 15.3858 28 15.6925 28 16C28 35.8822 44.1177 52 64 52C83.8822 52 100 35.8822 100 16C100 15.6925 99.9961 15.3858 99.9884 15.0801C99.85 9.56072 103.743 4 109.264 4H367C371.418 4 375 7.58172 375 12V406H0.000528336L0 12C0 7.58173 3.58172 4 8 4H18.7359Z" fill="url(#paint0_linear_2_107894)" fill-opacity="0.7" shape-rendering="crispEdges" />
            <path d="M18.7359 4.5C21.3157 4.5 23.5339 5.79657 25.1039 7.78056C26.677 9.76846 27.578 12.4241 27.5117 15.0676C27.5039 15.3775 27.5 15.6883 27.5 16C27.5 36.1584 43.8416 52.5 64 52.5C84.1584 52.5 100.5 36.1584 100.5 16C100.5 15.6883 100.496 15.3775 100.488 15.0676C100.422 12.4241 101.323 9.76845 102.896 7.78056C104.466 5.79657 106.684 4.5 109.264 4.5H367C371.142 4.5 374.5 7.85786 374.5 12V405.5H0.500528L0.5 12C0.5 7.85787 3.85786 4.5 8 4.5H18.7359Z" stroke="#A6FF03" shape-rendering="crispEdges" />
          </g>
          <defs>
            <filter id="filter0_bd_2_107894" x="-40" y="-36" width="455" height="482" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_107894" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.65098 0 0 0 0 1 0 0 0 0 0.0117647 0 0 0 1 0" />
              <feBlend mode="normal" in2="effect1_backgroundBlur_2_107894" result="effect2_dropShadow_2_107894" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2_107894" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_2_107894" x1="157.5" y1="4" x2="157.5" y2="296" gradientUnits="userSpaceOnUse">
              <stop stop-color="#19191F" />
              <stop offset="1" stop-color="#3E3E43" />
            </linearGradient>
          </defs>
        </svg> : <svg width="500" height="508" viewBox="0 0 500 508" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_bd_1_253039)">
            <path d="M22.7359 4C28.257 4 32.15 9.56073 32.0115 15.0801C32.0039 15.3858 32 15.6925 32 16C32 35.8822 48.1177 52 68 52C87.8822 52 104 35.8822 104 16C104 15.6925 103.996 15.3858 103.988 15.0801C103.85 9.56072 107.743 4 113.264 4H492C496.418 4 500 7.58172 500 12V496C500 500.418 496.418 504 492 504H12C7.58172 504 4 500.418 4 496V12C4 7.58173 7.58172 4 12 4H22.7359Z" fill="url(#paint0_linear_1_253039)" fill-opacity="0.7" shape-rendering="crispEdges" />
            <path d="M22.7359 4.5C25.3157 4.5 27.5339 5.79657 29.104 7.78056C30.6771 9.76846 31.578 12.4241 31.5117 15.0676C31.5039 15.3775 31.5 15.6883 31.5 16C31.5 36.1584 47.8416 52.5 68 52.5C88.1584 52.5 104.5 36.1584 104.5 16C104.5 15.6883 104.496 15.3775 104.488 15.0676C104.422 12.4241 105.323 9.76845 106.896 7.78056C108.466 5.79657 110.684 4.5 113.264 4.5H492C496.142 4.5 499.5 7.85786 499.5 12V496C499.5 500.142 496.142 503.5 492 503.5H12C7.85786 503.5 4.5 500.142 4.5 496V12C4.5 7.85787 7.85786 4.5 12 4.5H22.7359Z" stroke="#A6FF03" shape-rendering="crispEdges" />
          </g>
          <defs>
            <filter id="filter0_bd_1_253039" x="-36" y="-36" width="576" height="580" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_253039" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.65098 0 0 0 0 1 0 0 0 0 0.0117647 0 0 0 1 0" />
              <feBlend mode="normal" in2="effect1_backgroundBlur_1_253039" result="effect2_dropShadow_1_253039" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_253039" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_1_253039" x1="161.5" y1="4" x2="161.5" y2="296" gradientUnits="userSpaceOnUse">
              <stop stop-color={theme.customColors.backgroundPrimary} />
              <stop offset="1" stop-color={theme.customColors.backgroundPrimary} />
            </linearGradient>
          </defs>
        </svg>}

        < StyledAvatar
          src={avatarIcon}
        />
        <BoxContent>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "14px",
            marginLeft: "5rem",
            marginBottom: "3rem",
            [theme.breakpoints.down("md")]: {
              marginBottom: "2rem"
            }
          }}>
            <Typography fontWeight={700} fontSize={isMobile ? "1rem" : "1.25rem"} lineHeight={1.2}>Digital Learning Coach</Typography>
            <ButtonText
              sx={{
                background: theme.customColors.systemSecondary05,
                borderRadius: "8px",
                width: "32px",
                height: "32px",
                minWidth: "unset",
                padding: 1
              }}
              onClick={() => closeTutorial()}
            >
              <Icon
                icon={"close"}
                size={19}
                color={theme.customColors.systemPrimary02}
              />
            </ButtonText>
          </Box>
          <Typography fontWeight={700} fontSize={isMobile ? "1.25rem" : "2rem"} lineHeight={1.1} marginBottom={2.5}>{steps[activeStep].title}</Typography>
          <Typography fontSize={isMobile ? "1rem" : "1.25rem"} lineHeight={1.2} marginBottom={2}>
            {steps[activeStep].firstText}
          </Typography>
          <Typography fontSize={isMobile ? "1rem" : "1.25rem"} lineHeight={1.2}>
            {steps[activeStep].secondText}
          </Typography>
          <Box sx={{
            marginTop: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
            <Box sx={{
              border: "1px solid #FFFFFF",
              padding: "2px 10px",
              background: "#FFFFFF26",
              borderRadius: "8px",
              fontSize: "1rem",
              fontWeight: "700",
              lineHeight: 1.3
            }}>
              0{steps.indexOf(steps[activeStep]) + 1}/0{steps.length}
            </Box>
            <ButtonText
              onClick={() => {
                if(steps.length - 1 === activeStep) {
                  // localStorage.setItem("tutorial-completed", "true");
                  closeTutorial()
                }
                if (isMobile) {
                  goNextStepMobile()
                } else {
                  goToNextStep()
                }
              }}
              sx={{
                textTransform: "capitalize",
                [theme.breakpoints.down("sm")]: {
                  borderRadius: "2px",
                  minWidth: "151px",
                  zIndex: theme.zIndex.appBar
                }
              }}
            >
              {steps.length - 1 === activeStep ? t("begin") : t("continue")}
            </ButtonText>
          </Box>
        </BoxContent>
      </BoxCmt>
    </Dialog>
  );
}

export default TutorialBox;
